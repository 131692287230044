import React from 'react'
import PropTypes from 'prop-types'
import ReactDisqusComments from 'react-disqus-comments'

import config from '../../config'
import styles from './index.module.scss'

export const Article = ({ title, date, contentHTML, disqusPath }) => {
  return (
    <section>
      <h1>{title}</h1>
      <div className={styles.meta}>Post on {date}</div>
      <article
        className={styles.article}
        dangerouslySetInnerHTML={{ __html: contentHTML }}
      />
      {disqusPath && config.disqusShortname ? (
        <ReactDisqusComments
          className={styles.comments}
          shortname={config.disqusShortname}
          identifier={title}
          title={title}
          url={`${config.baseURL}${disqusPath}`}
        />
      ) : null}
    </section>
  )
}

Article.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  contentHTML: PropTypes.string.isRequired,
  path: PropTypes.string,
}
