import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import { Article } from '../components/article'
import { NoteTree } from '../components/note-tree'

class NoteTemplate extends React.Component {
  render() {
    const {
      location,
      data: {
        site: { siteMetadata },
        markdownRemark,
      },
    } = this.props

    const { title, date } = markdownRemark.frontmatter

    return (
      <Layout location={location} sidebar={<NoteTree />}>
        <Helmet title={`${title} | ${siteMetadata.title}`} />
        <Article title={title} date={date} contentHTML={markdownRemark.html} />
      </Layout>
    )
  }
}

export default NoteTemplate

export const pageQuery = graphql`
  query NoteByPath($path: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { notePath: { eq: $path } }) {
      id
      html
      timeToRead
      excerpt
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
      fields {
        notePath
      }
    }
  }
`
