import React from 'react'
import { Treebeard, decorators } from 'react-treebeard'
import { graphql, useStaticQuery } from 'gatsby'
import { TreeBeardHeader } from './TreeBeardHeader'
import treeBeardTheme from './tree-beard-theme'
import { TreeBeardToggle } from './TreeBeardToggle'
import { useGlobalState } from '../../hooks/use-global-state'

const query = graphql`
  query FileTreeQuery {
    allMarkdownRemark(sort: { order: ASC, fields: fields___notePath }) {
      edges {
        node {
          fields {
            notePath
          }
        }
      }
    }
  }
`

/**
 * @param {array} paths each `path` in `paths` should satisfy `path.length >= 1`
 */
function convertPathsToTree(paths, parentPath = []) {
  const { restPathsByName, names } = paths.reduce(
    (result, path) => {
      const name = path[0]
      const restPath = path.slice(1)

      if (!result.restPathsByName.hasOwnProperty(name)) {
        result.restPathsByName[name] = []
        result.names.push(name)
      }

      if (restPath.length > 0) {
        result.restPathsByName[name].push(restPath)
      }

      return result
    },
    {
      restPathsByName: {},
      names: [],
    },
  )

  const folders = []
  const files = []
  names.forEach(name => {
    const restPaths = restPathsByName[name]
    const result = { id: name, name }
    const currentPath = [...parentPath, name]
    if (restPaths.length > 0) {
      result.children = convertPathsToTree(restPaths, currentPath)
      folders.push(result)
    } else {
      result.path = currentPath
      files.push(result)
    }
  })

  return [...folders, ...files]
}

let activeNodeBeforeChangingPage

export const NoteTree = () => {
  const { allMarkdownRemark } = useStaticQuery(query)
  const edges = allMarkdownRemark.edges
  const paths = React.useMemo(
    () =>
      edges.map(edge => {
        let notePath = edge.node.fields.notePath
        if (notePath.startsWith('/')) {
          notePath = notePath.slice(1)
        }

        if (notePath.endsWith('/')) {
          notePath = notePath.slice(0, -1)
        }

        return notePath.split('/')
      }),
    [edges],
  )

  const fileData = React.useMemo(() => {
    const tree = convertPathsToTree(paths)
    // We don't need to show top level `notes` directory
    return tree[0].children
  }, [paths])
  const [treeData, setTreeData] = useGlobalState('noteTree', fileData)

  const activeNoteRef = React.useRef(null)

  React.useEffect(() => {
    if (activeNodeBeforeChangingPage) {
      activeNodeBeforeChangingPage.active = false
      activeNodeBeforeChangingPage = null
    }

    return () => {
      activeNodeBeforeChangingPage = activeNoteRef.current
    }
  }, [])

  const onToggle = (node, toggled) => {
    node.active = true
    if (node.children) {
      node.toggled = toggled
    }

    if (activeNoteRef.current && activeNoteRef.current !== node) {
      activeNoteRef.current.active = false
    }
    activeNoteRef.current = node

    setTreeData(treeData.slice())
  }

  return (
    <Treebeard
      data={treeData}
      style={treeBeardTheme}
      decorators={{
        ...decorators,
        Header: TreeBeardHeader,
        Toggle: TreeBeardToggle,
      }}
      onToggle={onToggle}
    />
  )
}
