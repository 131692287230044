import React from 'react'

export const ToggleIcon = props => (
  <svg
    viewBox="0 0 128 128"
    fillRule="evenodd"
    strokeLinejoin="round"
    {...props}
  >
    <path
      d="M36.534 14.026c.378.089.464.072.978.455l56 48a2.104 2.104 0 0 1 0 3.038l-56 48c-1.627 1.212-4.843-.835-2.603-3.038L89.137 64 34.909 17.519s-.999-1.109-.565-2.237c.323-.84 1.183-1.351 2.19-1.256z"
      fillRule="nonzero"
    />
  </svg>
)
