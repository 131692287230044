import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import cx from 'classnames'

import config from '../../config'

// Don't change import order here!
import '../../styles/base.scss'
import Header from '../header'
import Footer from '../footer'
import styles from './index.module.scss'

const pathPrefix = config.pathPrefix || ''

const pageQuery = graphql`
  query LayoutIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

class Layout extends Component {
  render() {
    const {
      children,
      sidebar,
      location: { pathname },
    } = this.props

    return (
      <StaticQuery
        query={pageQuery}
        render={data => {
          const { title } = data.site.siteMetadata
          return (
            <div className={cx(styles.root, sidebar && styles.withSidebar)}>
              <Header
                className={styles.header}
                siteTitle={title}
                isHomePage={
                  pathname === pathPrefix || pathname === `${pathPrefix}/`
                }
              />
              <main className={styles.main}>
                {sidebar && <aside className={styles.sidebar}>{sidebar}</aside>}
                <div className={styles.content}>{children}</div>
              </main>
              <Footer className={styles.footer} />
            </div>
          )
        }}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node,
  sidebar: PropTypes.node,
  location: PropTypes.object,
}

export default Layout
