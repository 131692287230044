// Icon by: https://www.iconfinder.com/icons/314418/document_text_icon

import React from 'react'

export const FileIcon = props => (
  <svg viewBox="0 0 32 32" {...props}>
    <path
      d="M19.5 3H9.003A2.005 2.005 0 0 0 7 5.007v22.986A2 2 0 0 0 8.997 30h15.006A1.999 1.999 0 0 0 26 28.01V10l-6-7h-.5zM19 4H8.996C8.446 4 8 4.455 8 4.995v23.01c0 .55.455.995 1 .995h15c.552 0 1-.445 1-.993V11h-4.002A1.995 1.995 0 0 1 19 8.994V4zm1 .5v4.491c0 .557.45 1.009.997 1.009H24.7L20 4.5zM10 10v1h7v-1h-7zm0-3v1h7V7h-7zm0 6v1h13v-1H10zm0 3v1h13v-1H10zm0 3v1h13v-1H10zm0 3v1h13v-1H10zm0 3v1h13v-1H10z"
      fillRule="evenodd"
    />
  </svg>
)
