import React from 'react'

const stateByKey = {}

export function useGlobalState(key, initialState) {
  if (!stateByKey[key]) {
    stateByKey[key] = initialState
  }

  const [reactState, reactSetState] = React.useState(stateByKey[key])
  const setState = newState => {
    stateByKey[key] = newState
    reactSetState(newState)
  }

  return [reactState, setState]
}
