import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Link } from 'gatsby'

// import Nav from './Nav'
import styles from './index.module.scss'

export default function Header({ siteTitle, isHomePage, className }) {
  const rootClassName = cx(styles.header, className, {
    [styles.isHomePage]: isHomePage,
  })

  const titleElem = isHomePage ? (
    siteTitle
  ) : (
    <Link to="/" className={styles.titleLink}>
      {siteTitle}
    </Link>
  )

  return (
    <header className={rootClassName}>
      <h1 className={styles.title}>{titleElem}</h1>
      {/* <Nav className={styles.nav} /> */}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  isHomePage: PropTypes.bool,
  className: PropTypes.string,
}
