import React from 'react'
import PropTypes from 'prop-types'
import { FolderIcon } from './FolderIcon'
import { FileIcon } from './FileIcon'
import { Link } from 'gatsby'

export const TreeBeardHeader = ({ style, node }) => {
  return (
    <>
      {node.children ? (
        <FolderIcon style={{ ...style.icon, ...style.folderIcon }} />
      ) : (
        <FileIcon style={{ ...style.icon, ...style.fileIcon }} />
      )}
      {node.path ? (
        <Link to={`/${node.path.join('/')}/`} style={style.name}>
          {node.name}
        </Link>
      ) : (
        <span style={style.name}>{node.name}</span>
      )}
    </>
  )
}

TreeBeardHeader.propTypes = {
  node: PropTypes.object,
  style: PropTypes.object,
}
