import { colorPrimary, colorTextSecondary } from '../../styles'

const arrowSize = 12
const iconSize = 16
const arrowIconPadding = 6
const iconNamePadding = 6
const arrowColor = colorTextSecondary

export default {
  tree: {
    base: {
      listStyle: 'none',
      // reset default value
      backgroundColor: undefined,
      margin: 0,
      padding: 0,
      color: colorTextSecondary,
    },
    node: {
      base: {
        position: 'relative',
      },
      link: {
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      },
      activeLink: {
        color: colorPrimary,
        // reset default value
        background: undefined,
      },
      toggle: {
        base: {
          position: 'relative',
          display: 'inline-block',
          fill: arrowColor,
          width: arrowSize,
          height: arrowSize,
          marginRight: arrowIconPadding,
          flexShrink: 0,
        },
      },
      header: {
        icon: {
          marginRight: iconNamePadding,
          width: iconSize,
          height: iconSize,
          fill: 'currentColor',
          flexShrink: 0,
        },
        fileIcon: {
          marginLeft: arrowSize + arrowIconPadding,
        },
        name: {
          fontSize: '14px',
          whiteSpace: 'nowrap',
          color: 'inherit',
        },
      },
      subtree: {
        listStyle: 'none',
        paddingLeft: arrowSize + arrowIconPadding,
      },
    },
  },
}
