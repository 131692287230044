// Icon by: https://www.iconfinder.com/icons/314937/folder_icon

import React from 'react'

export const FolderIcon = props => (
  <svg viewBox="0 0 32 32" {...props}>
    <title />
    <path
      d="M17 9l-2-4H4.003C2.897 5 2 5.89 2 6.991V25.01C2 26.109 2.9 27 3.993 27h25.014c1.1 0 1.993-.893 1.993-1.995v-14.01A2 2 0 0 0 28.994 9H17zm-.64 1L14.4 6H3.992A1 1 0 0 0 3 6.992v18.016c0 .548.446.992.993.992h25.014A.995.995 0 0 0 30 25V11c0-.552-.454-1-1.003-1H16.36z"
      fillRule="evenodd"
    />
  </svg>
)
