import React from 'react'
import PropTypes from 'prop-types'
import { ToggleIcon } from './ToggleIcon'

export const TreeBeardToggle = ({ style }) => {
  return <ToggleIcon style={style.base} />
}

TreeBeardToggle.propTypes = {
  style: PropTypes.object,
}
